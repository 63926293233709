.HeroName {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.HeroName .main-text {
  font-size: 3.5rem;
}

.HeroName .main-text.accent {
  color: var(--text-color-name);
}

.HeroName .slogan {
  line-height: 1.2;
  font-size: 2rem;
  color: var(--text-color-slogan);
}