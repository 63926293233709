.NavBar-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 1.5rem;
  margin: 1.5rem 0.5rem 0;
  z-index: 1;
  background-color: var(--bg-color-secondary-dark);
  backdrop-filter: blur(1.5rem);
  border-radius: var(--border-radius);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.NavBar {
  background-color: var(--bg-color-secondary-dark);
  backdrop-filter: blur(1.5rem);
}

.NavBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  visibility: hidden;
}

.NavBar.active {
  visibility: visible;
  width: 100%;
  height: 100%;
}

.button {
  cursor: pointer;
}

.NavBar .close.button {
  position: absolute;
  top: 2.5rem;
  left: 1.5rem;
  align-self: flex-start;
}

.NavBar .NavBar-item {
  font-size: 2rem;
  font-weight: bold;
  text-decoration: none;
  color: var(--text-color-main-dark);
}

.NavBar .NavBar-item:visited {
  color: inherit;
}

.nav-icon {
  height: 24px;
  width: 24px;
}

.NavBar .nav-icon-link {
  
}

.NavBar .external-links-container {
  display: flex;
  gap: 2rem;
}