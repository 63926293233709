.DesktopNav {
  position: -webkit-sticky;
  position: sticky;
  top: 1.5rem;
  margin: 1.5rem 0.5rem 0;
  z-index: 1;
  background-color: var(--bg-color-secondary-dark);
  backdrop-filter: blur(1.5rem);
  border-radius: var(--border-radius);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DesktopNav .NavBar-item {
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: var(--text-color-main-dark);
}

.NavBar .NavBar-item:visited {
  color: inherit;
}

.nav-group {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav-flex-link {
  display: flex;
  align-items: center;
  justify-content: center;
}