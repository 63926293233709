.ProjectCard {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  background-color: var(--bg-color-secondary-dark);
  border-radius: var(--border-radius);
  backdrop-filter: blur(1.5rem);
}

.project-image,
.project-name,
.project-description {
  margin-bottom: 1rem;
}

.project-name {
  font-size: 2rem;
}

.project-image {
  border-radius: inherit;
  aspect-ratio: 3/2;
  width: 100%;
}

.project-links {
  margin-top: auto;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-links div {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.project-links div:not(:last-child) {
  border-right: 1px solid var(--text-color-muted);
}

.project-links a {
  text-decoration: none;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.link-icon {
  height: 24px;
  width: 24px;
}