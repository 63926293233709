.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App .main-container {
  padding: 1.5rem;
  flex-grow: 1;
}
